<template>
  <div class="card-align">
    <!-- {{ permissions }} -->
    <div class="card">
      <div class="card-body">
        <div class="card-design mr-btm">
          <div class="d-flex align-items-center">
            <div class="my-auto">
              <div v-if="editableValue">Name</div>
              <div
                id="rolename"
                :class="[{ bordereditable: editableValue }, 'font-Weight-700']"
                :contenteditable="editableValue"
              >
                {{ currentData.name }}
              </div>
            </div>
          </div>
          <div class="pointer algin-right">
            <span @click="editViewDetails()" class="icon-box-new-user-role">
              <feather-icon class="rotate" icon="EditIcon" size="20" />
            </span>
          </div>
        </div>
      </div>
    </div>
    <div></div>
    <!-- <div
      :class="[
        { noClick: !editableValue },
        'full-width mr-right-20  inline-flex ',
      ]"
    >
      <div class="width-50">
        <div class="margin-left-20 padding-btm">Permissions</div>
        <div class="wraper-div">
          <input
            v-model="searchResult"
            placeholder="Search"
            class="border-none mr-bottom form-control input-sm flex"
          />
          <ul class="list-permission scroll-div">
            <li
              v-for="permission in tagsFilter"
              @click="selectedDtaVal(permission._id)"
            >
              {{ permission.name }}
            </li>
          </ul>
          <div>
            <div @click="selectAll()"><label>Select All</label></div>
            <b-button
              @click="updateDetailsView()"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
            >
              Update
            </b-button>
          </div>
        </div>
      </div>
      <div class="width-50">
        <div class="th-div">
          <input
            v-model="searchResultSelected"
            placeholder="Search"
            class="mr-bottom border-none form-control input-sm flex"
          />
        </div>
        <div class="scroll-div">
          <ul class="selected-uldata">
            <li class="loop-data" v-for="data in tagsFilterSelected">
              {{ data.name }}
              <span
                @click="deleteSelectedPermission(data.id)"
                class="flot-right"
              >
                <feather-icon class="rotate" icon="Trash2Icon" size="15" />
              </span>
            </li>
          </ul>
          <div class="deselect" @click="deSelectAll()">
            <label>Deselect All</label>
          </div>
        </div>
      </div>
    </div> -->
    <div class="row">
      <div class="col-6">
        <p>Permissions</p>
        <AdminRoleList
          :items="permissions"
          :buttonFunction="addList"
          :searchFunction="search"
        />
        <button @click="selectallpermision" class="btn btn-secondary btn-sm">select all</button>
      </div>
      <div class="col-6">
       <p>Selected Permissions</p>
        <AdminRoleList
          :items="addlist"
          :buttonAddType="false"
          :buttonFunction="removeList"
          :searchFunction="searchadd"
        />
        <button @click="deselectallpermision" class="btn btn-secondary btn-sm">deselect all</button>
      </div>
    </div>
    
     
    <div class="row mt-1">
      <div class="col-12">
        <button class="btn btn-primary" @click="updateDetailsView" :disabled="!this.$store.state.auth.permissions.includes('role-update')">
          Update Role
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {
  BFormTextarea,
  BSidebar,
  VBToggle,
  BPagination,
  BButton,
} from "bootstrap-vue";
import AdminRoleList from "../components/AdminRoleList.vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { VueAutosuggest } from "vue-autosuggest";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  data: () => ({
    roleid: "",
    currentData: [],
    editableValue: false,
    roleName: "",
    selected: [],
    allPermission: [],
    newUserOptionEdit: [],
    selectedAssetEditName: "",
    tagInputProps: {
      class: "form-control",
      placeholder: "Add new",
    },
    selectedAssetEditData: [],
    autoData: [],
    searchResult: "",
    searchData: [],
    searchResultSelected: "",
    permissions: [],
    addlist: [],
    pmids: [],
    removeditem: [],
  }),
  components: {
    vSelect,
    VueAutosuggest,
    BButton,
    AdminRoleList,
  },
  directives: {
    Ripple,
  },
  props: {},
  watch: {
    // addlist(){
    //   // this.getRolePermission();
    // },
  },
  computed: {
    // tagsFilter: function () {
    //   var textSearch = this.searchResult;
    //   return this.allPermission.filter(function (el) {
    //     return el.name.toLowerCase().indexOf(textSearch.toLowerCase()) !== -1;
    //   });
    // },
    // tagsFilterSelected: function () {
    //   var textSearch = this.searchResultSelected;
    //   return this.searchData.filter(function (el) {
    //     return el.name.toLowerCase().indexOf(textSearch.toLowerCase()) !== -1;
    //   });
    // },
  },
  computed: {
  
  },
  mounted() {
    this.roleid = this.$route.params.id;
    this.allPermission = this.getPermission();

    this.getRoles(this.roleid);
    this.getRolePermission();
  },
  methods: {
    // search(name) {
    //   // this.permissions = this.permissions.find(name)
    //   console.log(name);
    // },
    selectallpermision(){
      
      this.addlist = this.permissions;
      this.permissions = [];
      

    },
    deselectallpermision(){
      this.permissions = this.addlist;
      this.addlist = [];
    },
      search(name) {
      // let tempRecipes = this.recipes;
      // if(name == ""){
      //   this.getPermission();
      // }
      if(name == ''){
        this.getPermission();
      }
      if (name != "" && name) {
        this.permissions = this.permissions.filter((item) => {
          return item.name
            .includes(name);
        });
      }
      
      return this.permissions;
     
    },
     searchadd(name) {
      // let tempRecipes = this.recipes;
      if(name == ''){
       this.getRolePermission();
      }

      if (name != "" && name) {
        this.addlist = this.addlist.filter((item) => {
          return item.name
            .includes(name);
        });
      }
      return this.addlist;
    },
    addList(id, index) {
      //   this.addlist = item;
      //    this.permissions.find((ele) => ){

      //     this.addlist.push(ele)
      //   }

      this.permissions.forEach((ele) => {
        if (ele._id == id) {
          this.addlist.push(ele);
          const filtersList = this.permissions.filter(
            (element) => element !== ele
          );
          this.permissions = filtersList;
          // this.permissions.splice(id,index)
        }
      });
    },
    removeList(item, index) {
     
      this.removeditem = this.addlist.splice(index,1);
      this.permissions.push(this.removeditem[0]);

    },
    selectAll() {
      this.allPermission.forEach((ele) => {
        this.searchData.push({ name: ele.name, id: ele._id });
      });
    },
    deSelectAll() {
      this.searchData = [];
    },
    deleteSelectedPermission(id) {
      this.searchData = this.searchData.filter((ele) => ele.id != id);
    },
    selectedDtaVal(id) {
      this.allPermission.forEach((element) => {
        if (element._id == id) {
          if (!this.searchData.find((o) => o.id === id)) {
            this.searchData.push({ name: element.name, id: element._id });
          }
        }
      });
    },
    getRoles(id) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/roles/${id}`,
      };
      this.$http(options)
        .then((res) => {
          this.currentData = res.data.data;
          this.pmids = this.currentData.permission_ids;
          //   this.currentData.permission_ids.forEach((ele) => {
          //     this.allPermission.filter((el) => {
          //       if (ele == el._id) {
          //         this.searchData.push({
          //           id: el._id,
          //           name: el.name,
          //         });
          //         this.selectedAssetEditName = "";
          //       }
          //     });
          //   });
        })
        .catch((err) => {
          console.log(err);
        });
      // }
    },
    editViewDetails() {
      this.editableValue = true;
    },
    updateDetailsView() {
      const valueData = [];
      this.addlist.forEach((ele) => {
        valueData.push(ele._id);
      });
      this.roleName = document.getElementById("rolename").innerText;
      let data = {
        name: this.roleName,
        permission_ids: valueData,
      };

      const options = {
        method: "PUT",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/roles/${this.roleid}`,
      };
      this.$http(options)
        .then((res) => {
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
          this.roleName = "";
          this.editableValue = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPermission() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/permissions`,
      };
      this.$http(options)
        .then((res) => {
          this.permissions = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getRolePermission() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/permissions`,
      };
      this.$http(options)
        .then((res) => {
          console.log(this.pmids);
          this.addlist = res.data.data.filter(
            (elem) => this.pmids.indexOf(elem._id) != -1
          );
          this.permissions = res.data.data.filter(
            (elem) => this.pmids.indexOf(elem._id) == -1
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.flex-inline {
  display: inline-flex;
  margin-right: 5px;
}
.list-permission {
  padding: 0;
}
.mr-btm {
  margin-bottom: 15px;
}
.border-none {
  border: none;
}
.margin-left-20 {
  margin-left: 20px;
}
.list-permission li {
  list-style: none;
  padding: 5px;
  // border: 1px solid #cfcfcf;
  border-bottom: none;
}
.scroll-div {
  height: 200px;
  overflow: hidden;
  overflow-y: scroll;
}
.full-width {
  width: 100%;
}
.selected-uldata li {
  list-style: none;
}
.selected-uldata {
  padding: 0;
}
.deselect {
  position: absolute;
  bottom: 0;
  top: 665px;
}
.mr-right {
  margin-right: 5px;
}
.padding-btm {
  padding-bottom: 0px !important ;
}
.card-design {
  display: flex;
  justify-content: space-between;
}
// .mr-50 {
//   margin-right: 100px !important;
// }
.flex {
  display: flex;
}
.padding-card {
  padding-bottom: 1.5rem;
}
.bordereditable {
  border: 1px solid #d1cdcd;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
  outline: none;
  width: 400px;
  // height: 35px;
}
.mr-right {
  margin-right: 10px;
}
.parantDiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  top: -37px;
  position: relative;
  margin-left: 52px;
}
.loop-data {
  padding: 10px;
  background-color: #e5e5e5;
  border-top: 1px solid #cfcfcf;
  margin-right: 20px;
}
.inline-flex {
  display: inline-flex;
}
.label-text {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 700;
}
.th-div {
  margin-top: 42px;
  // border-radius: 5px;
}
.flot-right {
  float: right;
}
.width-40 {
  width: 40%;
}
.width-50 {
  width: 50% !important;
  // border: 1px solid grey !important;
}
.padding-20 {
  padding-right: 20px;
}
.wraper-div {
  padding: 20px;
}
.algin-right {
  display: flex;
  align-items: center;
  justify-content: end;
}
.textarea-editable {
  -moz-appearance: textfield-multiline;
  -webkit-appearance: textarea;
  border: 1px solid #d1cdcd;
  font: medium -moz-fixed;
  font: -webkit-small-control;
  height: 65px;
  overflow: auto;
  padding: 2px;
  resize: both;
  width: 400px;
  border-radius: 5px;
  outline: none;
}
.font-Weight-700 {
  font-weight: 700;
}
.icon-box-new-user-role {
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  display: inline-flex;
}
.text-primary-black {
  color: #ffff;
}
.meetup-header {
  margin-bottom: 2rem;
}
.meetup-day {
  border-right: 1px solid #d8d6de;
  padding-right: 1.3rem;
  margin-right: 1.3rem;
  text-align: center;
}
.mr-right-20 {
  margin-right: 20px;
}
.noClick {
  pointer-events: none;
  cursor: not-allowed !important;
}
</style>
